import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { APPLICATION_NAME, DEFAULT_IMAGE_URL, IMAGE_BASE_URL, PACCAR_URL } from 'app/app.constants';
import { Branch } from 'entities/branch';
import { CartResult } from 'entities/cart-result';
import { Customer } from 'entities/customer';
import { Permission } from 'entities/enums';
import { Note } from 'entities/notes';
import { Part } from 'entities/part';
import { PartBinLocationItem } from 'entities/part-bin-location-item';
import { AlternatePartsRequest } from 'entities/parts/alternate-parts-request';
import { PartSearchResponse } from 'entities/parts/part-search-response';
import { RelatedPartsRequest } from 'entities/parts/related-parts-request';
import { ToastType } from 'entities/toast-type';
import { getImageHelper } from 'helpers/get-image';
import { PartBinLocationsModalComponent } from 'modals/part-bin-locations-modal/part-bin-locations-modal.component';
import { BehaviorSubject, combineLatest, iif, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, first, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthorizationService } from 'services/authorization.service';
import { ConfigurationService } from 'services/configuration.service';
import { FavoritePartsService } from 'services/favorite-parts.service';
import { SourceLocationType } from 'services/logger.service';
import { LoyaltyService } from 'services/loyalty.service';
import { PartService } from 'services/part.service';
import { ToastService } from 'services/toast.service';
import { AppState } from 'store/app-state';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as CartActions from 'store/cart/cart.actions';
import * as CartSelectors from 'store/cart/cart.selectors';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import * as LoyaltySelectors from 'store/loyalty/loyalty.selectors';
import { PartQuantityComponent } from '../part-quantity/part-quantity.component';
import { PartDetailsComponentStore } from './part-details.component.store';

@Component({
  selector: 'part-details',
  templateUrl: './part-details.component.html',
  providers: [PartDetailsComponentStore]
})
export class PartDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('partDetail') set partsData(value: PartSearchResponse) {
    this.componentStore.setPart(value?.parts[0]);
  }
  @ViewChild('suggestedPartsNav', { static: true }) suggestedPartsNav: NgbNav;
  @ViewChild('primaryPartQuantity') primaryQuantity: PartQuantityComponent;
  public sapCuratedNote$: Observable<string> = this.componentStore.sapCuratedNote$;
  public notes$: Observable<Note[]> = this.componentStore.notes$;
  public noteCount$: Observable<number> = this.componentStore.noteCount$;
  part$: Observable<Part> = this.componentStore.part$.pipe(tap((part: Part) => {
    this.isFavoriteSubject.next(part.isFavoritePart);
  }));
  isFavoriteSubject = new BehaviorSubject(false);
  public isFavorite$: Observable<boolean> = this.isFavoriteSubject.asObservable();
  displayPartNumber$: Observable<string> = this.componentStore.displayPartNumber$;

  isQuote$: Observable<boolean> = this.store.select(CartSelectors.selectIsQuote);
  branch$: Observable<Branch> = this.store.select(BranchSelectors.selectedBranch).pipe(tap((branch) => this.branchCode = branch.code));
  customer$: Observable<Customer> = this.store.select(CustomerSelectors.selectedCustomer).pipe(tap((customer) => this.customerNumber = customer.customerNumber));
  cart$: Observable<CartResult> = this.store.select(CartSelectors.selectCart);
  isInternal$: Observable<boolean> = this.store.select(ConfigurationSelectors.isInternal);
  cartIsLocked$: Observable<boolean> = this.store.select(CartSelectors.selectIsCartLocked);

  branchCode: string;
  customerNumber: string;

  relatedParts$: Observable<Part[]> = this.componentStore.part$
    .pipe(
      distinctUntilChanged((x, y) => x?.rushPartNumber === y?.rushPartNumber),
      withLatestFrom(this.branch$, this.customer$),
      map(([part, branch, customer]): RelatedPartsRequest => ({
        partId: part?.partId,
        branchCode: this.branchCode,
        customerNumber: this.customerNumber,
        materialId: part?.rushPartNumber
      })),
      switchMap((searchData): Observable<Part[]> => this.partService.getRelatedParts(searchData)
        .pipe(
          map((response) => response.parts),
          catchError((error) => {
            this.toastService.errorMessage('PartsDetailsComponent', 'getRelatedParts', 'getRelatedParts', null);
            return of([]);
          })
        )
      ),
      withLatestFrom(
        this.store.select(BranchSelectors.selectedBranch),
        this.store.select(LoyaltySelectors.loyaltyAccount)
      ),
      switchMap(([parts, branch, loyaltyAccount]) => {
        if (parts.length > 0 && loyaltyAccount) {
          return this.loyaltyService.getDiscounts({
            memberId: loyaltyAccount.loyaltyAccountIdentifier,
            partNumbers: parts.map(x => x.rushPartNumber),
            branchCode: this.branchCode,
            loyaltyProgram: loyaltyAccount.loyaltyProgram
          }).pipe(map((discounts) => {
            return parts.map(part => {
              const coupon: any = discounts[part.rushPartNumber.split(":")[0]];
              if (coupon) {
                const loyaltyProgram = loyaltyAccount.loyaltyProgram;
                part.sapCoupons = [
                  ...part.sapCoupons,
                  {
                    amount: coupon.amount,
                    type: 'part',
                    description: coupon.code + " " + loyaltyProgram.charAt(0).toUpperCase() + loyaltyProgram.slice(1),
                    code: coupon.code
                  }
                ];
              }
              return part;
            });
          }));
        }
        return of(parts);
      }),
      map((relatedParts) => relatedParts.sort(this.compareAvailability)),
      tap((relatedParts) => {
        if (!relatedParts.length) {
          setTimeout(() => this.suggestedPartsNav.select('alternateParts'));
        }
      }),
      shareReplay(1)
    );

  alternateParts$: Observable<Part[]> = this.componentStore.part$
    .pipe(
      distinctUntilChanged((x, y) => x?.rushPartNumber === y?.rushPartNumber),
      switchMap((part) =>
        iif(
          () => part?.groupId !== 0,
          combineLatest([this.branch$, this.customer$])
            .pipe(
              first(),
              map(([branch, customer]): AlternatePartsRequest => ({
                partId: part?.partId,
                branchCode: this.branchCode,
                customerNumber: this.customerNumber,
                materialId: part?.rushPartNumber,
                groupId: part?.groupId,
                pageSize: 50
              })),
              switchMap((searchData): Observable<Part[]> => this.partService.getAlternateParts(searchData)
                .pipe(
                  map((response) => response.parts),
                  withLatestFrom(
                    this.store.select(BranchSelectors.selectedBranch),
                    this.store.select(LoyaltySelectors.loyaltyAccount)
                  ),
                  switchMap(([parts, branch, loyaltyAccount]) => {
                    if (parts.length > 0 && loyaltyAccount) {
                      return this.loyaltyService.getDiscounts({
                        memberId: loyaltyAccount.loyaltyAccountIdentifier,
                        partNumbers: parts.map(x => x.rushPartNumber),
                        branchCode: this.branchCode,
                        loyaltyProgram: loyaltyAccount.loyaltyProgram
                      }).pipe(map((discounts) => {
                        return parts.map((part: Part) => {
                          const coupon: any = discounts[part.rushPartNumber.split(":")[0]];
                          if (coupon) {
                            const loyaltyProgram = loyaltyAccount.loyaltyProgram;
                            part.sapCoupons = [
                              ...part.sapCoupons,
                              {
                                amount: coupon.amount,
                                type: 'part',
                                description: coupon.code + " " + loyaltyProgram.charAt(0).toUpperCase() + loyaltyProgram.slice(1),
                                code: coupon.code
                              }
                            ];
                          }
                          return part;
                        });
                      }));
                    }
                    return of(parts);
                  }),
                  catchError((error) => {
                    this.toastService.errorMessage('PartsDetailsComponent', 'getAlternateParts', 'getAlternateParts', null);
                    return [];
                  })
                )
              ),
              map((alternateParts) => alternateParts.sort(this.compareAvailability))
            ),
          of([])
        )
      ),
      shareReplay(1)
    );

  partSearchTerm: string;
  previousPageUrl: string;
  feedbackType: string;
  additionalDetails: string;
  isImageFeedbackActive: boolean;
  relatedPartType: SourceLocationType = SourceLocationType.RelatedParts;
  alternatePartType: SourceLocationType = SourceLocationType.AlternateParts;
  isWarehouseManaged: boolean = false;
  today = Date;

  permission: typeof Permission = Permission;
  canReturnCore$: Observable<boolean>;

  getBinLocations$: Observable<PartBinLocationItem[]>;
  partBinLocations: PartBinLocationItem[] = [];
  binLocationsLoadingSubject: Subject<boolean> = new BehaviorSubject(false);

  hasFixedBinLocations$: Observable<boolean>;
  filteredBinLocations$: Observable<any>;
  showBinLocationsModalSubject: Subject<void> = new Subject();
  addToFavoritePartsSubject = new Subject<Part>();
  removePartFromFavoritesSubject = new Subject<Part>();

  showBinLocationsModal$: Observable<any>;

  addToFavoriteParts$ = this.addToFavoritePartsSubject.pipe(
    switchMap(
      part => this.favoritePartService.addPartToFavorites(part).pipe(
        tap(() => {
          this.isFavoriteSubject.next(true);
        })
      )
    )
  );

  removePartFromFavorites$ = this.removePartFromFavoritesSubject.pipe(
    switchMap(
      part => this.favoritePartService.removePartFromFavorites(part).pipe(
        tap(() => {
          this.isFavoriteSubject.next(false);
        })
      )
    )
  );

  subscriptions: Subscription;
  paccarUrl = PACCAR_URL;

  constructor(
    private store: Store<AppState>,
    private componentStore: PartDetailsComponentStore,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    public authorizationService: AuthorizationService,
    private partService: PartService,
    private title: Title,
    @Inject(APPLICATION_NAME) appName: string,
    private modalService: NgbModal,
    private favoritePartService: FavoritePartsService,
    private router: Router,
    @Inject(IMAGE_BASE_URL) public imageBaseUrl: string,
    @Inject(DEFAULT_IMAGE_URL) public defaultImage: string,
    private loyaltyService: LoyaltyService,
    private configurationService: ConfigurationService
  ) {
    title.setTitle(`${appName} - Details`);
    this.store.dispatch(CartActions.getCartDataForBranchAndCustomer());
  }

  ngOnInit() {
    this.canReturnCore$ = this.authorizationService.hasPermissionAsync(Permission.ReadCoreReturnOption)
    .pipe(
      map((hasPermission) => hasPermission
    ));

    this.getBinLocations$ = combineLatest([this.part$, this.branch$])
      .pipe(
        debounceTime(1),
        distinctUntilChanged(([partX, branchX], [partY, branchY]) =>
          partX?.rushPartNumber === partY?.rushPartNumber
          && branchX?.code === branchY?.code
        ),
        filter(([part, branch]) => branch.isWarehouseManaged && part?.number.length > 0),
        tap(() => this.binLocationsLoadingSubject.next(true)),
        switchMap(([part, branch]) =>
          this.partService.getPartBinLocations(
            [part?.number],
            branch.code
          )
            .pipe(
              map((locations) => locations[part?.number.toLowerCase()])
            )
        ),
        tap(() => this.binLocationsLoadingSubject.next(false)),
        shareReplay(1)
      );

    this.hasFixedBinLocations$ = this.getBinLocations$
      .pipe(
        map((binLocations: PartBinLocationItem[]) => binLocations && binLocations.length &&
          binLocations.filter(x => x.fixedBin) && true)
      );

    this.filteredBinLocations$ = this.getBinLocations$
      .pipe(
        map((locations: PartBinLocationItem[]) =>
        locations && locations.filter(x => (x.storageType === 'SHO' || x.storageType === 'PCK') && ( x.quantityAvailable > 0 || x.fixedBin === 'X' ) ))
      );

    this.showBinLocationsModal$ = this.showBinLocationsModalSubject.pipe(
      withLatestFrom(this.getBinLocations$),
      tap(([_, binLocations]: [any, PartBinLocationItem[]]) => {
        if (binLocations) {
          const modalRef = this.modalService.open(PartBinLocationsModalComponent);
          modalRef.componentInstance.partBinLocationItems = binLocations;
        }
      })
    );

    this.subscriptions = merge(this.removePartFromFavorites$, this.addToFavoriteParts$, this.showBinLocationsModal$).subscribe();
  }

  ngAfterViewInit() {
    this.primaryQuantity?.quantityInput.nativeElement.focus();
  }

  _keyPress(event: KeyboardEvent) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  private compareAvailability(part1: Part, part2: Part) {
    if (part1.quantityAvailable && !part2.quantityAvailable) {
      return -2;
    }
    if (part1.isExtendedToBranch && !part2.isExtendedToBranch) {
      return -1;
    }
    if (!part1.quantityAvailable && part2.quantityAvailable) {
      return 2;
    }
    if (!part1.isExtendedToBranch && part2.isExtendedToBranch) {
      return 1;
    }
    return 0;
  }

  getNotes(): void {
    this.componentStore.refreshNotes();
  }

  copyPartNumberToClipBoard(part: Part) {
    const contentToCopy = part?.isRushPart ? part?.rushPartNumber : part?.number;
    window.navigator['clipboard'].writeText(contentToCopy);
    this.toastService.showToast(`${contentToCopy} copied to clipboard.`, ToastType.Success);
  }

  openNationalInventory(part: Part) {
    this.store.dispatch(CartActions.showNationalInventoryModal({ part: {...part, source: SourceLocationType[SourceLocationType.PartDetailResult] } }));
  }

  // TODO: Include this code in refactoring of AppInsights
  // trackWithAppInsights(addToCartItem: any) {
  //   const sourceName = 'PartsDetailsComponent_addToCart';
  //   const metricName = this.loggerService.getMetricValue(sourceName);
  //   const user: User = this.isNullorUndefined(sessionStorage.getItem('User')) ? null : JSON.parse(sessionStorage.getItem('User'));
  //   const appInsightAddToCart: AppInsightAddToCart = {
  //     userId: user.id,
  //     customerNumber: this.customer$.pipe(map((customer) => customer.customerNumber)).toString(),
  //     customerName: this.customer$.pipe(map((customer) => customer.customerName)).toString(),
  //     branchNumber: this.branchCode,
  //     cartDetails: this.commonDataService.CartDetails,
  //     source: SourceLocationType[SourceLocationType.PartDetailResult],
  //     plMetricName: sourceName,
  //     product: undefined
  //   };
  //   appInsightAddToCart.product = this.loggerService.getAppInsightParts(addToCartItem, JSON.stringify(appInsightAddToCart).length);
  //   this.loggerService.trackMetric(metricName, appInsightAddToCart);
  // }

  addToCart(addToCartItem: Part) {
    this.store.dispatch(CartActions.addItemToCart({
      addCartItemRequest: {
        partId: addToCartItem.partId,
        partNumber: addToCartItem.rushPartNumber,
        binLocation: addToCartItem.binLocation,
        quantity: addToCartItem.quantity,
        trackingParameters: JSON.stringify({}),
        coreOption: addToCartItem.coreOption,
        adjustedPrice: addToCartItem.adjustedPrice,
        description: addToCartItem.description,
        quantityAvailable: addToCartItem.quantityAvailable,
        verifiedPrice: addToCartItem.verifiedPrice,
        isPriceVerified: addToCartItem.isPriceVerified,
        sapCoupons: addToCartItem.sapCoupons
      },
      sourceLocationType: this.activatedRoute.snapshot.queryParams['src']
    }));
    const element = document.getElementById('headerSearch_text_SearchTerm');
    element.focus();
  }

  changePart(part: Part) {
    this.componentStore.changePart(part);
  }
  changeFavorite(part: Part, type: string): void {
    if (type === 'relatedParts') {
      this.relatedParts$ = this.relatedParts$.pipe(
        map((parts: Part[]) => {
          parts.splice(parts.findIndex(a => a.partId == part.partId), 1, part);
          return [...parts];
        })
      );
    } else {
      this.alternateParts$ = this.alternateParts$.pipe(
        map((parts: Part[]) => {
          parts.splice(parts.findIndex(a => a.partId == part.partId), 1, part);
          return [...parts];
        })
      );
    }
  }
  changeQuantity(quantity: number) {
    this.componentStore.changeQuantity(quantity);
  }

  changeCoreOption(coreOption: string) {
    this.componentStore.changeCoreOption(coreOption);
  }

  addToFavoriteParts(part: Part) {
    this.addToFavoritePartsSubject.next(part);
  }

  removeFavoritePart(part: Part) {
    this.removePartFromFavoritesSubject.next(part);
  }

  showBinLocations() {
    this.showBinLocationsModalSubject.next();
  }

  onExtendPart(extendedPartNumber: string) {
    const originalShouldReuseRoute = this.router.routeReuseStrategy.shouldReuseRoute;
    const originalOnSameUrlNavigation = this.router.onSameUrlNavigation;
    try {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['parts'], { queryParams: { searchTerm: extendedPartNumber } });
    } finally {
      setTimeout(()=> {
        this.router.routeReuseStrategy.shouldReuseRoute = originalShouldReuseRoute;
        this.router.onSameUrlNavigation = originalOnSameUrlNavigation;
      });
    }
  }

  isNullorUndefined(obj: any) {
    return obj === undefined || obj == null || obj === 'null';
  }

  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
  }

  public getImage(image: string, imageBaseUrl: string, imageSize: string) {
    return getImageHelper(image, imageBaseUrl, imageSize );
  }

}

export class AppInsightsPriceOverride {
  userId: any;
  branch: any;
  part: any;
  date: Date;
}
