import { createAction, props } from "@ngrx/store";
import { I2pResendRequest } from "app/_components/invite-to-pay/invite-to-pay.model";
import { CartResult } from "entities/cart-result";
import { Customer } from "entities/customer";
import { ShipToCustomer } from 'entities/ship-to-customer';
import { StoredPaymentMethod } from "entities/stored-payment-method";
import { CustomerState } from "./customer.state";

export const findCustomers = createAction(
  '[CustomerSearch] Find Customers',
  props<{
    parentSource: string,
    searchTerm: string,
    customerNumber: string,
    customerName: string,
    searchCity: string,
    searchState: string,
    searchPostalCode: string,
    searchPhoneNumber: string }>()
);

export const findCustomersSuccess = createAction(
  '[CustomerSearch] Find Customers Success',
  props<{ customer: Customer; payers: Customer[]; }>()
)

export const findCustomersFailed = createAction(
  '[Customer Search] Find Customer Number Failed',
  props<{ error: any; tracking?: {
    parentSource: string,
    searchTerm: string,
    customerNumber: string,
    customerName: string,
    searchCity: string,
    searchState: string,
    searchPostalCode: string,
    searchPhoneNumber: string,
    branchCode: string
 }; }>()
);

export const openCustomerModal = createAction(
  '[Customer] Open Customer Modal',
  props<{ customers: Customer[], payers: Customer[]}>()
);

export const selectCustomer = createAction(
  "[Customer] Select Customer",
  props<{customer: Customer;}>()
);

export const clearCustomer = createAction(
  "[Customer] Clear Customer"
);

export const setDefaultCustomer = createAction(
  "[Customer] Set Default Customer",
  props<{ defaultCustomer: Customer }>()
);

export const clearCustomerFailed = createAction(
  "[Customer] Clear Customer Failed",
  props<{ error: any; }>()
);

export const getPayers = createAction(
  '[Customer] Get Payers'
);
export const getPayersSuccess = createAction(
  '[Customer] Get Payers Success',
  props<{payers: Customer[]}>()
);
export const getPayersFailed = createAction(
  '[Customer] Get Payers Failed',
  props<{ error: any; }>()
);
export const getPayersForQuote = createAction(
  '[Customer] Get Payers For Quote',
  props<{customerNumber: string; branchCode: string}>()
);
export const getPayersForQuoteSuccess = createAction(
  '[Customer] Get Payers For Quote Success',
  props<{payers: Customer[]}>()
);
export const getPayersForQuoteFailed = createAction(
  '[Customer] Get Payers For Quote Failed',
  props<{ error: any; }>()
);
export const getCustomerNotes = createAction(
  '[Customer] Get Customer Notes',
  props<{ customerNumber: string }>()
);

export const loadShipToCustomers = createAction(
  '[Shipping] ShipTo Results Load', props<{ openShipToModal: boolean, customerNumber?: string }>()
);

export const cancelLoadShipToCustomers = createAction(
  '[Shipping] ShipTo Cancel Results Load', props<{ openShipToModal: boolean }>()
)

export const loadShipToCustomersSuccess = createAction(
  '[Shipping] ShipTo Results Load Success', props<{ customers: Customer[], openShipToModal: boolean }>()
);

export const setShipToAddressCancel = createAction(
  '[Shipping] ShipTo Cancel'
);

export const clearShipTo = createAction(
  "[Shipping] Clear Ship To"
);

export const loadShipToCustomersFailure = createAction(
  '[Shipping] ShipTo Results Load Failure'
);

export const openShipToModal = createAction(
  '[Shipping] Open Modal'
);

export const setShipToAddress = createAction(
  '[Shipping] Set ShipTo Address', props<{ shipToAddress: ShipToCustomer }>()
);

export const setShipToEditing = createAction(
  '[Shipping] Set ShipTo Editing', props<{ shipToEditing: boolean }>()
)

export const getFavoriteCustomers = createAction(
  '[Customer] Get Customer Favorites'
);

export const getFavoriteCustomersSuccess = createAction(
  '[Customer] Get Customer Favorites Success',
  props<{favoriteCustomers: Customer[]}>()
);

export const getFavoriteCustomersFailed = createAction(
  '[Customer] Get Customer Favorites Failed',
  props<{error: any;}>()
);

export const addFavoriteCustomer = createAction(
  '[Customer] Add Customer To Favorites',
  props<{customerNumber: string}>()
);

export const addFavoriteCustomerSuccess = createAction(
  '[Customer] Add Customer To Favorites Success',
  props<{customer: Customer}>()
);

export const addFavoriteCustomerFailed = createAction(
  '[Customer] Add Customer To Favorites Failed',
  props<{error: any;}>()
);

export const removeFavoriteCustomer = createAction(
  '[Customer] Remove Customer From Favorites',
  props<{customerNumber: string}>()
);

export const removeFavoriteCustomerSuccess = createAction(
  '[Customer] Remove Customer From Favorites Success',
  props<{customerId: string}>()
);

export const removeFavoriteCustomerFailed = createAction(
  '[Customer] Remove Customer From Favorites Failed',
  props<{error: any;}>()
);

export const loadCustomerStateFromCache = createAction(
  '[Customer] Load Customer State From Cache',
  props<{customerData: CustomerState;}>()
);

export const getMscPayersFailed = createAction(
  '[Customer] Get MSC Payers Failed',
  props<{error: any;}>()
);

export const getMscPayersSuccess = createAction(
  '[Customer] Get MSC Payers Success',
  props<{payerNumber: string; mscCardNumber: string;}>()
);
export const getStoredPaymentMethods = createAction(
  '[Customer] Get Stored Payment Methods'
);

export const addPaymentMethod = createAction(
  '[Customer] Add Customer Payment Method',
  props<{customerNumber: string;}>()
);

export const getStoredPaymentMethodsSuccess = createAction(
  '[Customer] Get Stored Payment Method Success',
  props<{storedPaymentMethods: StoredPaymentMethod[];}>()
);

export const getStoredPaymentMethodsEmpty = createAction(
  '[Customer] Get Stored Payment Methods Empty'
);

export const getStoredPaymentMethodsFailed = createAction(
  '[Customer] Get Stored Payment Methods Failed',
  props<{error: any;}>()
);

export const setStoredPaymentToken = createAction(
  '[Customer] Set Stored Payment Token',
  props<{storedPaymentMethod: StoredPaymentMethod | null}>()
);

export const getStoredPaymentToken = createAction(
  '[Customer] Get Stored Payment Token'
);

export const showAddPaymentMethod = createAction(
  '[Customer] Show Add Payment Method',
  props<{showiFrame: boolean}>()
);

export const inviteToPay = createAction(
  '[Customer] Invite To Pay',
  props<{customerNumber: string;}>()
);

export const setInviteToPay = createAction(
  '[Customer] Set Invite To Pay',
  props<{inviteToPayType: string; email: string; sms: string;}>()
);

export const clearInviteToPay = createAction(
  '[Customer] Clear Invite To Pay'
);

export const setSelectedPaymentMethod = createAction(
  '[Customer] Set Selected Payment Method',
  props<{paymentMethod: string}>()
);

export const setSelectedPaymentMethodSuccess = createAction(
  '[Customer] Set Selected Payment Method Success',
  props<{cart: CartResult}>()
);

export const setSelectedPaymentMethodFailed = createAction(
  '[Customer] Set Selected Payment Method Failed',
  props<{error: any;}>()
);
export const getPaymentSelections = createAction(
  '[Customer] Get Payment Selections'
);

export const inviteToPayResend = createAction(
  '[Customer] Invite To Pay Resend',
  props<{i2pResendRequest: I2pResendRequest, onSuccess: Function}>()
);

export const inviteToPayResendSuccess = createAction(
  '[Customer] Invite To Pay Resend Success'
);

export const inviteToPayResendFailed = createAction(
  '[Customer] Invite To Pay Resend Failed'
);
