import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, shareReplay } from "rxjs";
import { FeatureFlag } from "store/feature-flags/feature-flags.state";

@Injectable({
    providedIn: "root"
})
export class FeatureFlagApiService {

    public featureFlags$: Observable<{ [FeatureName: string]: FeatureFlag } > = this.httpClient.get<{ [FeatureName: string]: FeatureFlag }>('api/FeatureFlags/GetFeatureFlags')
    .pipe(
      shareReplay(1)
    );

    constructor(
        private httpClient: HttpClient
      ) { }

    public getFeatureFlags(): Observable<{ [FeatureName: string]: FeatureFlag }>  {
        return this.featureFlags$;
    }
}
