import { CheckoutShipToFormConfig } from "../checkout-ship-to/checkout-ship-to.models";

export interface CheckoutValidationError {
    mapKey: string;
    value: any;
    message?: string;
};

export const CheckoutValidationMessages = {
    "transactionType.required":                     "Transaction type is required",
    "orderChannel.required":                        "Order channel is required",
    "altEmail.required":                            "Primary Email field is mandatory.",
    "altEmail.internal-freight.required":           "Primary email address is required for shipping and tracking notifications.",
    "altEmail.email":                               "Primary Email format is incorrect.",
    "deliveryType.required":                        "Delivery option is required.",
    "cartData.disallowWillCall":                    "Will Call - Delivery option not allowed. Parts picked to Shopping Basket.",
    "cartData.disallowShoppingBasketReturns":       "Basket/Returns/Showroom - only available for in-stock, non-procurement items",
    "cartData.shoppingBasketItemQuote":             "Cannot place a quote with shopping basket items",
    "cartData.disallowSelfPick":                    "Front Counter Self Pick - only available for in-stock, non-BAS and non-procurement items",
    "cartData.disallowWarehousePick":               "Front Counter Warehouse Pick - Delivery option not allowed. Parts picked to Shopping Basket",
    "cartData.disallowRTCDelivery":                 "Rush Truck Center delivery - Delivery option not allowed. Parts picked to Shopping Basket",
    "cartData.disallowFreightDelivery":             "Freight delivery - Delivery option not allowed. Parts picked to Shopping Basket",
    "cartData.disallowShipDirect":                  "Ship direct to customer - Delivery option not allowed. Parts picked to Shopping Basket",
    "cartData.requireDeliveryOption":               "There are one or more parts in your cart that require a delivery option.",
    "cartData.requireProcurement":                  "There are one or more parts in your cart that require procurement. Click Back To Cart to fix them.",
    "requiredProperty":                             "Alternate shipping address is required.",
    "procuredAllLines.required":                    "There are one or more parts in your cart that require procurement. Click Back To Cart to fix them.",
    "smsOptContactName.required":                   "Contact name is required in Opt In RTC Delivery - Order and Delivery Status.",
    "smsOptMobileNumber.required":                  "Mobile number is required in Opt In RTC Delivery - Order and Delivery Status.",
    "shipto.required":                              "Alternate shipping address is required.",
    "shipto.dirty":                                 "To continue, please click on update button.",
    "contactName.required":                         "Contact name is required in Opt In RTC Delivery - Order and Delivery Status.",
    "mobileNumber.required":                        "Mobile number is required in Opt In RTC Delivery - Order and Delivery Status.",
    "mobileNumber.pattern":                         "Mobile number is required in Opt In RTC Delivery - Order and Delivery Status.",
    "poNumber.required":                            "PO number is required.",
    "billTo.valid":                                 "Select Payment Customer.",
    "billTo.required":                              "Select Payment Customer.",
    "shipto.customerName.required":                 "Customer name is required.",
    "shipto.customerName.maxlength":                `Customer name can only have a maximum of ${CheckoutShipToFormConfig.customerName.maxLength} characters.`,
    "shipto.streetAddress.maxlength":               `Street address can only have a maximum of ${CheckoutShipToFormConfig.streetAddress.maxLength} characters.`,
    "shipto.streetAddress.required":                "Street address is required.",
    "shipto.city.required":                         "City is required.",
    "shipto.state.required":                        "State is required.",
    "shipto.postalCode.required":                   "Postal code is required.",
    "shipto.phoneNumber.required":                  "Phone number is required.",
    "shipto.phoneNumber.pattern":                   "Enter a valid phone number.",
    "shipto.postalCode.pattern":                    "Enter a valid postal code.",
    "i2p.required":                                 "Please choose which desired contact channel for Invite To Pay",
    "i2pEmail.email":                               "Invite to Pay Email format is incorrect.",
    "i2pEmail.required":                            "Invite to Pay Email is required.",
    "i2pSms.required":                              "Invite to Pay SMS/Phone is required.",
    "i2pSms.minlength":                             "Invite to Pay SMS/Phone must be 10 digits.",
    "i2pSms.pattern":                               "Invite to Pay SMS/Phone must follow pattern 000{-}000{-}0000.",
    "cartData.freightdeliveryoption.required":      "Please select freight delivery option.",
    "cartData.disallowFreightDeliveryHazmat":       "Freight delivery - Delivery option not allowed. Part has Hazmat",
    "addedCCResult.required":                       "Please submit your Credit Card for authorization.",
    "addedCCResult.pattern":                        "Authorization for the added Credit Card has failed. Please try again or select a different payment method to place order.",
    "unitNumber.pattern":                           "No symbols or special characters (#,$,_,@, etc.) allowed in Unit number.",
    "billToCC.required":                            "Please make a Credit Card payment choice."
};
