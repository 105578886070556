<form #ngForm="ngForm" (ngSubmit)="selectPart(ngForm.form.value, submitter)">
  <div class="modal-body">
    <part-select name="part" ngModel (ngModelChange)="checkPartNumber($event)" [displayType]="displayType"
    [initialSearchTerm]="initialSearchTerm" [branch]="branch" [customer]="customer" required></part-select>
  </div>
  <a class="hyperlink px-4" (click)="openPartsBuyoutModal();">
    Parts Buyout
  </a>
  <div class="modal-footer">
    <button (click)="returnToRequest();" *ngIf="displayType === 'back-counter-tool'" class="btn btn-secondary">Return to Request</button>
    <button *ngIf="displayType === 'navistar-punchout'" class="btn btn-primary" type="submit" (click)="setSubmitter('submit')" [disabled]="!ngForm.form.valid">Submit</button>
    <button *ngIf="hideNext" class="btn btn-primary" type="submit" (click)="setSubmitter('next')" [disabled]="!ngForm.form.valid">Go to Next</button>
  </div>
</form>
