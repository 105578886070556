import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import * as MyCartComponentActions from 'app/_components/my-cart/my-cart.component.actions';
import { FeaturedPart } from 'entities/featured-part';
import { SuggestedSellingAction, SuggestedSellingComponent } from "modals/suggested-selling/suggested-selling.component";
import { of } from "rxjs";
import { catchError, filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { CartService } from "services/cart.service";
import { ModalService } from "services/modal.service";
import { PartService } from 'services/part.service';
import { AppState } from "store/app-state";
import * as BasketActions from 'store/basket/basket.actions';
import * as BranchActions from 'store/branch/branch.actions';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as CartActions from 'store/cart/cart.actions';
import * as CartSelectors from 'store/cart/cart.selectors';
import * as CustomerActions from 'store/customer/customer.actions';
import * as CustomerSelectors from 'store/customer/customer.selectors';
import * as FeaturedPartsActions from "store/featured-parts/featured-parts.actions";
import * as FeaturedPartsSelectors from 'store/featured-parts/featured-parts.selectors';
import * as LoyaltyActions from 'store/loyalty/loyalty.actions';

@Injectable({
  providedIn: "root"
})
export class FeaturedPartsEffects {

  showLastPitch$ =
    createEffect(() =>
      this.action$.pipe(
        ofType(FeaturedPartsActions.showLastPitch),
        withLatestFrom(
          this.store.select(CartSelectors.selectCart),
          this.store.select(FeaturedPartsSelectors.selectFeaturedParts)
        ),
        tap(([_, cart, featuredParts]) => {
          const showLastPitch = !cart.hideFinalPitch && featuredParts?.length > 0;
          if (showLastPitch) {
            this.store.dispatch(FeaturedPartsActions.openPromosModal());
          } else {
            this.router.navigate(["/checkout"]);
          }
        })
      ),
      { dispatch: false }
    )

  openModal$ =
    createEffect(() =>
      this.action$.pipe(
        ofType(FeaturedPartsActions.openPromosModal),
        switchMap((_) =>
          this.modalService.open(SuggestedSellingComponent, null, {
            size: "lg",
            backdrop: "static",
            keyboard: false,
          })
        ),
        withLatestFrom(this.store.select(CartSelectors.selectedCartId)),
        filter(([{action}]) => action === SuggestedSellingAction.CONTINUE),
        map(([{gotoCheckout}, cartId]) => {
          this.store.dispatch(MyCartComponentActions.finalPitchSuccess({ isSuccess: gotoCheckout }));
          return FeaturedPartsActions.hideFinalPitch({ cartId });
        })
      )
    );

  hideFinalPitch$ =
  createEffect(() =>
    this.action$
      .pipe(
        ofType(FeaturedPartsActions.hideFinalPitch),
        switchMap(
          ({cartId}) => this.cartService.hideFinalPitch(cartId).pipe(
            map(() =>  FeaturedPartsActions.hideFinalPitchSuccess()),
            catchError(error => of(error))
          )
        )
      )
    );

  finalPitchSuccess$ =
    createEffect(() =>
      this.action$.pipe(
        ofType(MyCartComponentActions.finalPitchSuccess),
        filter((payload) => payload.isSuccess),
        tap(() => {
          this.router.navigate(["/checkout"]);
        })
      ),
    { dispatch: false }
  )

  applyDiscountsOnFeatureParts$ =
    createEffect(() =>
      this.action$
        .pipe(
          ofType(LoyaltyActions.loadLoyaltyDiscountsSuccess),
          map((discounts) => FeaturedPartsActions.applyLoyaltyDiscountsOnFeaturedParts(discounts))
        )
    )

  clearFeaturedPartsOnBranchOrCustomerChange$ =
    createEffect(() =>
      this.action$
        .pipe(
          ofType(
            CustomerActions.selectCustomer,
            BranchActions.selectBranch
          ),
          map(() => FeaturedPartsActions.clearFeaturedParts())
        )
    )

  getFeaturedParts$ =
    createEffect(() =>
      this.action$
        .pipe(
          ofType(
            CartActions.addItemToCartSuccess,
            BasketActions.addBasketToCartSuccess,
            CartActions.addItemToCartFromPartsBuyoutSuccess
          ),
          withLatestFrom(
            this.store.select(BranchSelectors.selectedBranch),
            this.store.select(CustomerSelectors.selectedCustomer),
            this.store.select(FeaturedPartsSelectors.featurePartsCheckedForCurrentBranchAndCustomer)
          ),
          switchMap(([_, branch, customer, featurePartsChecked]) => {
            if (!featurePartsChecked && branch && customer) {
              this.store.dispatch(FeaturedPartsActions.getFeaturedParts());
              return this.partService.getFeaturedParts(branch?.code, customer?.customerNumber?.padStart(10, '0'))
              .pipe(
                switchMap(
                  (featuredParts: FeaturedPart[]) => [
                  FeaturedPartsActions.getFeaturedPartsSuccess({ featuredParts }),
                  LoyaltyActions.getLoyaltyDiscountsWithPromoParts()
                ]),
                catchError(error => of(FeaturedPartsActions.getFeaturedPartsFailure({error}))))
            } else {
              return of(LoyaltyActions.getLoyaltyDiscountsWithPromoParts());
            }
          })
        )
  );

  constructor(
    private cartService: CartService,
    private action$: Actions,
    private store: Store<AppState>,
    private modalService: ModalService,
    private router: Router,
    private partService: PartService,
  ) { }

}
