import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Branch } from "entities/branch";
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class BranchService {
  public branches: Branch[];
  public branches$: Observable<Branch[]>;

  constructor(private http: HttpClient) {
    const queryParameters = {
      'query': '',
      'take': 500,
      'includeLeasing': false
    };
    const queryString = Object.keys(queryParameters)
      .reduce((arr, queryParameter) => {
        const value = queryParameters[queryParameter];

        if (value === undefined || value === null) {
          return arr;
        }

        arr.push(`${queryParameter}=${value}`);

        return arr;
      }, [])
      .join('&');
    const url = 'api/Branches/GetUserBranchesForOrdering?' + queryString;
    this.branches$ = this.http.get<Branch[]>(url)
        .pipe(
          tap((branches) => this.branches = branches),
          shareReplay(1)
        );
  }

  GetUserBranchesForOrdering(): Observable<Branch[]> {
    return this.branches$;
  }

  loadBranch(branchCode: string) : Observable<Branch> {
    return this.branches$.pipe(
      map(branches => {
        if (!branchCode) {
          return {} as Branch;
        }
        return branches.find(branch => branch.code === branchCode);
      })
    )
  }
}
