<div class="modal-header">
</div>
<div class="modal-body">
    <div class="price-override-modal">
        <div class="row g-0 align-items-center mb-2">
            <label class="col-2" for="PriceOverrideModal_Text_EverydayPrice">Everyday Price</label>
            <div class="col-3">
                <input id="PriceOverrideModal_Text_EverydayPrice" class="form-control" type="text" [ngModel]="part.walkIn | currency" readonly />
            </div>
        </div>
        <div class="row g-0 align-items-center mb-2">
            <span class="col-2">Your Price</span>
            <div class="col-3">
                <input id="PriceOverrideModal_Text_ListPrice" class="form-control" type="text" [ngModel]="part.listPrice | currency" readonly />
            </div>
        </div>
        <div class="row g-0 align-items-center mb-2">
            <span class="col-2">Override Price</span>
            <div class="col-3"><input id="PriceOverrideModal_OverridePrice" class="form-control" type="text" [ngModelOptions]="{updateOn: 'blur'}"
                    [ngModel]="overridePrice | currency" (ngModelChange)="changeOverridePrice($event);"/>
            </div>
            <span class="col-2" style="text-align: center">$ Given up</span>
            <div class="col-2">
                <input id="PriceOverrideModal_Text_OverridePriceGivenUpReadOnly" *ngIf="!overridePrice" class="form-control" readonly>
                <input id="PriceOverrideModal_Text_OverridePriceGivenUp" *ngIf="overridePrice" [ngClass]="{'whitebg': !part.overrideFloor, 'bg-success': overridePrice >= part.overrideTarget, 'bg-danger': overridePrice < part.overrideFloor, 'bg-primary': overridePrice >= part.overrideFloor && overridePrice < part.overrideTarget }"
                class="form-control" type="text" [ngModel]="part.listPrice - overridePrice | currency" readonly>
            </div>
            <span class="col-2" style="text-align: center">% Discount</span>
            <div class="col-1">
                <input id="PriceOverrideModal_Text_DiscountReadOnly" *ngIf="!overridePrice" class="form-control" readonly>
                <input id="PriceOverrideModal_Text_Discount" *ngIf="overridePrice" [ngClass]="{'whitebg': !part.overrideFloor, 'bg-success': overridePrice >= part.overrideTarget, 'bg-danger': overridePrice < part.overrideFloor, 'bg-primary': overridePrice >= part.overrideFloor && overridePrice < part.overrideTarget }"
                class="form-control" type="text" [ngModel]="(1 - (overridePrice / part.listPrice)) | percent" readonly>
            </div>
        </div>
        <div class="row g-0 align-items-center mb-2">
                <label class="col-2" for="PriceOverrideModal_Select_ReasonCode">Reason Code</label>
                <select id="PriceOverrideModal_Select_ReasonCode" class="form-control col-7" name="PriceOverrideModal_ReasonCode" [(ngModel)]="reason" style="font-size: 15px;"
                        (ngModelChange)="changeReason($event)">
                        <option id="PriceOverrideModal_Option_ReasonCode_0" value="0">Competitive</option>
                        <option id="PriceOverrideModal_Option_ReasonCode_1" value="1">Incorrect in system</option>
                        <option id="PriceOverrideModal_Option_ReasonCode_2" value="2">Marketing</option>
                        <option id="PriceOverrideModal_Option_ReasonCode_3" value="3">Contract Price</option>
                </select>
        </div>
        <div class="row g-0 align-items-center mb-2">
            <span class="col-2">Competitor</span>
            <div class="col-7">
                <input id="PriceOverrideModal_Text_Competitor" class="form-control" type="text" [(ngModel)]="competitor"/>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="row">
            <div class="col-7 ms-1" style="flex:none;">I confirm that I have checked competitive pricing and wish to proceed with the price override.</div>
            <div class="g-0 align-items-center mb-2 mt-2">
              <button id="PriceOverrideModal_Button_Acknowledge" type="submit" class="btn btn-primary me-2" (click)="adjustPrice()" [disabled]="!reason || !competitor">Acknowledge</button>
              <button id="PriceOverrideModal_Button_CancelOverride" type="submit" class="btn btn-primary" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="closeModal()">Cancel Override</button>
            </div>
            <div *ngIf="warningMessage" class="alert m-1 mt-2 col"  [ngClass]="{'alert-danger': !part.overrideFloor || (overridePrice && overridePrice < part.overrideFloor),'alert-warning': overridePrice >= part.overrideFloor && overridePrice < part.overrideTarget }">
                <div id="PriceOverrideModal_Div_WarningMessage">{{warningMessage}}</div>
            </div>
    </div>
</div>
