import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppInsightAddToCart } from 'entities/app-insight-add-to-cart';
import { Observable, Subscription } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { AuthorizationService } from 'services/authorization.service';
import { CartService } from 'services/cart.service';
import { CommonDataService } from 'services/common-data.service';
import { LoggerService, SourceLocationType } from 'services/logger.service';
import { ToastService } from 'services/toast.service';
import * as CartSelectors from 'store/cart/cart.selectors';
import { selectFeaturedParts } from 'store/featured-parts/featured-parts.selectors';
import { CartResultLineItem } from '../../_entities/cart-result';
import { FeaturedPart } from '../../_entities/featured-part';
import { AppState } from '../../_store/app-state';

export enum SuggestedSellingAction {
  CONTINUE = "continue",
  CANCEL = 'cancel'
}

@Component({
    selector: 'suggested-selling',
    templateUrl: './suggested-selling.component.html',
    styleUrls: ['./suggested-selling.component.scss'],
})
export class SuggestedSellingComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    featuredPartNationalInventoryAdded$: Observable<boolean>;
    firstFeaturedPart: FeaturedPart;
    hasAcceptedOffer: boolean = false;
    procurementRequired: boolean = false;
    declineReasontext: string;
    partType: SourceLocationType = SourceLocationType.FeaturedProduct;

    branchCode: string;
    alternaterelatedCoreOption: string = 'NOCORER';
    source = SourceLocationType.SuggestedSelling;

    cartId$: Observable<string> = this.store.select(CartSelectors.selectedCartId);
    featuredParts$: Observable<FeaturedPart[]> = this.store.select(selectFeaturedParts).pipe(
        withLatestFrom(this.store.select(CartSelectors.selectCartItems)),
        tap(([featuredParts, cartItems]) => {
          this.firstFeaturedPart = featuredParts[0];
          this.verifyCartContainsFeatureParts(featuredParts, cartItems);
        }),
        map(([featuredParts, _]) => this.mapFeaturedParts(featuredParts)),
        map(x =>
          x.map(x => ({...x})))
    );

  constructor(
        private modalRef: NgbActiveModal,
        private store: Store<AppState>,
        private activatedRoute: Router,
        private commonDataService: CommonDataService,
        private loggerService: LoggerService,
        private cartService: CartService,
        private toastService: ToastService,
        public authorizationService: AuthorizationService
    ) { }

    ngOnInit() {
      this.subscription = this.activatedRoute.events.pipe(
        filter(event => event instanceof NavigationStart),
        tap(() => this.modalRef.close({ action: SuggestedSellingAction.CANCEL, gotoCheckout: false })
      )).subscribe();

      this.featuredPartNationalInventoryAdded$ = this.cartService.featuredPartAdded.pipe(
        map( (value) => this.hasAcceptedOffer = value)
      )

    }

    // This method is called when user clicks on the Continue button
    goToCheckout(): void {
        if (this.procurementRequired && this.commonDataService.User.isInternal) {
            this.modalRef.close({ action: SuggestedSellingAction.CONTINUE, gotoCheckout: false });
        } else {
            this.modalRef.close({ action: SuggestedSellingAction.CONTINUE, gotoCheckout: true });
        }
    }

    onAcceptOffer(part, cartId: string) {

        this.hasAcceptedOffer = true;

        if (part.quantity > part.quantityAvailable && this.commonDataService.User.isInternal) {
            this.procurementRequired = true;
        }
        this.cartService.hideFinalPitch(cartId);
    }

    // This method is called when user clicks on No Thanks link
    onDeclineOffer(cartId: string): void {
        this.cartService.declineSuggestedPart(cartId)
            .then(c => {
                if (c && c.ErrorType && c.ErrorType !== 200) {
                    this.toastService.errorMessage('SuggestedSellingComponent', 'onDeclineOffer', 'declineFeaturedPart', c);
                } else {
                    this.goToCheckout();
                }
            })
            .catch(() => { });

        const sourceName = 'SuggestedSellingComponent_declinePart';
        const metricName = this.loggerService.getMetricValue(sourceName);
        const appInsightDeclinePart = Object.assign(new AppInsightAddToCart(), {
            userId: this.commonDataService.User.id,
            customerNumber: this.commonDataService.Customer.customerNumber,
            customerName: this.commonDataService.Customer.customerName,
            branchNumber: this.commonDataService.Branch.code,
            cartNumber: cartId,
            source: SourceLocationType[SourceLocationType.SuggestedSelling],
            plMetricName: sourceName
        });
        const suggestedSellingPart = {
          cartId: cartId,
          userId: this.commonDataService.User.id,
          branchCode: this.commonDataService.Branch.code,
          customerNumber: this.commonDataService.Customer.customerNumber,
          partId: this.firstFeaturedPart.partId,
          partNumber: this.firstFeaturedPart.rushPartNumber,
          couponId: this.firstFeaturedPart.couponId,
          responseReason: this.declineReasontext
        };
        appInsightDeclinePart.product = this.loggerService.getAppInsightParts(suggestedSellingPart, JSON.stringify(appInsightDeclinePart).length);
        this.loggerService.trackMetric(metricName, appInsightDeclinePart);
    }

    // This method will check if CartItems contain featureParts
    // it will set hasAcceptedOffer to true if the above is positive
    verifyCartContainsFeatureParts(featureParts: FeaturedPart[], cartItems: CartResultLineItem[]) {
      if (cartItems.some(c => featureParts.some(x => x.rushPartNumber === c.partNumber))) {
        this.hasAcceptedOffer = true;
      }
    }

    mapFeaturedParts(featuredParts: FeaturedPart[]) : FeaturedPart[] {
      const totalPartsToShow: number = 3;
      return featuredParts.slice(0, totalPartsToShow);
    }

    ngOnDestroy(): void {
      if (this.subscription && !this.subscription.closed) {
        this.subscription.unsubscribe();
      }

   }

}
