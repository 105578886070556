import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayType } from 'entities/back-counter-tool';
import { Branch } from 'entities/branch';
import { Customer } from 'entities/customer';
import { Part } from 'entities/part';
import { UnknownPartBuyoutModalComponent } from 'modals/unknown-part-buyout-modal/unknown-part-buyout-modal.component';

@Component({
  selector: 'part-search-modal',
  templateUrl: './part-search-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartSearchModalComponent {
  @Input() customer: Customer;
  @Input() branch: Branch;
  @Input() initialSearchTerm: string;
  @Input() hideNext: boolean;
  @Input() displayType: DisplayType;
  @Input() onSelectPart: (part: Part) => void;
  requestId: string;

  public submitter: 'submit' | 'next';

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal) { }

  checkPartNumber(rushPartNumber: Part | string) {
    if (typeof rushPartNumber === 'string') {
      this.activeModal.close({ rushPartNumber, action: 'submit' })
    }
  }

  setSubmitter(submitter: 'submit' | 'next') {
    this.submitter = submitter;
  }

  selectPart({ part }: { part: Part }, action: 'submit' | 'next') {
    if(this.displayType === 'navistar-punchout'){
      this.activeModal.close({ part, action });
      return;
    }
    if (this.onSelectPart && part) {
      this.onSelectPart(part);
    }
  }
  returnToRequest(){
    if(this.displayType === 'navistar-punchout'){
          this.activeModal.dismiss();
    }else{
      this.onSelectPart = null;
      this.activeModal.dismiss();
    }
  }

  async openPartsBuyoutModal(){
    const modal = this.modalService.open(UnknownPartBuyoutModalComponent);
    modal.componentInstance.requestId = "Not Null";
    const results = await modal.result;
    if(results !== undefined){
      console.log("results:", results);
      this.onSelectPart(results);
    }
  }
}
