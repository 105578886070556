<ng-container *ngLet="{ currencySymbol: currencySymbol$ | async } as vm">
<button id="UnknownPartBuyoutModal_Button_Close" type="button" class="close" data-dismiss="modal" aria-label="Close" tabindex="-1" (click)="closeModal()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-header">
  <h4 class="modal-title">Parts Buyout</h4>
</div>
<div class="modal-body">
  <form>
    <!--Vendor Start-->
    <h5 class="strong">Vendor</h5>
    <div *ngIf="authorizationService.hasPermission(permission.ReadVendors)">
      <vendor-panel *ngIf="!vendor" (vendorSelect)="onVendorSelect($event)"></vendor-panel>
      <!--selected Vendor details start-->
      <div *ngIf="vendor" class="row g-0 form-group">
        <div class="col-12 col-md-6">
          <div id="UnknownPartBuyoutModal_Div_VendorName" class="text-truncate strong" title="{{vendor.name}}">{{vendor.name}}</div>
          <div id="UnknownPartBuyoutModal_Div_VendorName2" class="text-truncate" title="{{vendor.name2}}" *ngIf="vendor.name2 | trim">{{vendor.name2}}</div>
          <div id="UnknownPartBuyoutModal_Div_VendorNumber" *ngIf="vendor.number | trim">{{vendor.number | customerNumber}}</div>
          <div id="UnknownPartBuyoutModal_Div_VendorAddress" class="text-truncate" title="{{vendor.address}}" *ngIf="vendor.address | trim">{{vendor.address}}</div>
          <div id="UnknownPartBuyoutModal_Div_VendorCityStateZip" class="text-truncate" title="{{[([vendor.city, vendor.state] | join:', '), vendor.zipCode] | join:' '}}"
            *ngIf="(vendor.city | trim) || (vendor.state | trim) || (vendor.zipCode | trim)">{{[([vendor.city, vendor.state] | join:', '), vendor.zipCode] | join:' '}}</div>
          <div id="UnknownPartBuyoutModal_Div_VendorPhone" *ngIf="vendor.phone | trim">{{vendor.phone}}</div>
        </div>
        <div class="col-12 col-md-6 gy-3">
          <div>
            <a id="UnknownPartBuyoutModal_A_AddToFavouriteVendors" role="button" class="hyperlink" href="javascript:void(0)" (click)="addToFavouriteVendors(vendor);" *ngIf="!vendor.isFavorite">Add
              '{{vendor.name}}' to Favorites</a>
            <a id="UnknownPartBuyoutModal_A_RemoveFromFavouriteVendors" role="button" class="hyperlink" href="javascript:void(0)" (click)="removeFavouriteVendors(vendor);" *ngIf="vendor.isFavorite">Remove
              '{{vendor.name}}' from Favorites</a>
          </div>
          <div>
            <a id="UnknownPartBuyoutModal_A_UseDifferentVendor" role="button" class="hyperlink" href="javascript:void(0)" (click)="useDifferentVendor();">Use a different vendor</a>
          </div>
        </div>
      </div>
      <!--selected Vendor details end-->
    </div>
    <!--Vendor End-->
    <div class="form-group">
      <input id="UnknownPartBuyoutModal_Text_PartNumber" class="form-control" type="text" [(ngModel)]="partNo" name="partNo" readonly="readonly"
        tabindex="-1">
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-sm-6">
          <label for="UnknownPartBuyoutModal_Text_PartNumberOnly">Part Number</label>
          <div class="position-relative">
            <input id="UnknownPartBuyoutModal_Text_PartNumberOnly" #UnknownPartBuyoutModal_Text_PartNumberOnly class="form-control" class="form-control" type="text"
              [(ngModel)]="partNoOnly" on-focusout="getPartData(UnknownPartBuyoutModal_Text_PartNumberOnly.value)" name="UnknownPartBuyoutModal_Text_PartNumberOnly"
              maxlength="40">
            <div style="position: absolute; top: 4px; right: 4px; z-index: 2;" *ngIf="showGettingPartsLoader">
              <span id="UnknownPartBuyoutModal_Span_CountLoader" class="countLoader"></span>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <label for="UnknownPartBuyoutModal_Text_Manufacturer">Manufacturer</label>
          <input id="UnknownPartBuyoutModal_Text_Manufacturer" class="form-control" type="text" [(ngModel)]="manufacturer" name="UnknownPartBuyoutModal_Text_Manufacturer"
            maxlength="40">
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-sm-6">
          <label for="UnknownPartBuyoutModal_Text_BuyoutAmount">Vendor Part Purchase Price</label>
          <div class="input-group">
              <span class="input-group-text">{{ vm.currencySymbol }}</span>
            <input id="UnknownPartBuyoutModal_Text_BuyoutAmount" class="form-control" type="text" [ngModel]="buyoutAmount"
              name="UnknownPartBuyoutModal_Text_BuyoutAmount" (keypress)="priceKeyPress($event)" (paste)="buyoutPriceOnPaste($event)"
              (focusout)="buyoutCorrectPrice($event.target.value)" maxlength="14">
          </div>
        </div>
        <div class="col-sm-6">
          <label for="UnknownPartBuyoutModal_Text_Price">Customer Part Purchase Price</label>
          <div class="input-group">
              <span class="input-group-text">{{ vm.currencySymbol }}</span>
            <input id="UnknownPartBuyoutModal_Text_Price" class="form-control" type="text" [ngModel]="price"
              name="price" (keypress)="priceKeyPress($event)" (paste)="priceOnPaste($event)" (focusout)="correctPrice($event.target.value)"
              maxlength="14">
          </div>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="isCorePart">
      <div class="row">
        <div class="col-sm-6">
          <label for="UnknownPartBuyoutModal_Text_VendorCore">Vendor Core Purchase Price</label>
          <div class="input-group">
              <span class="input-group-text">{{ vm.currencySymbol }}</span>
            <input id="UnknownPartBuyoutModal_Text_VendorCore" class="form-control " type="text" [ngModel]="vendorCorePurchasePrice | number:'1.2-2'"
              name="UnknownPartBuyoutModal_Text_VendorCore" (keypress)="priceKeyPress($event)" (paste)="vendorPriceOnPaste($event)"
              (focusout)="vendorCorrectPrice($event.target.value)" maxlength="14">
          </div>
        </div>
        <div class="col-sm-6">
          <label for="UnknownPartBuyoutModal_Text_CustomerCore">Customer Core Price</label>
          <div class="input-group">
              <span class="input-group-text">{{ vm.currencySymbol }}</span>
            <input id="UnknownPartBuyoutModal_Text_CustomerCore" class="form-control" type="text" [ngModel]="customerCorePrice | number:'1.2-2'"
              name="customerCorePrice" (keypress)="priceKeyPress($event)" (paste)="customerPriceOnPaste($event)"
              (focusout)="customerCorrectPrice($event.target.value)" maxlength="14">
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="UnknownPartBuyoutModal_Text_Description">Description</label>
      <input id="UnknownPartBuyoutModal_Text_Description" class="form-control" type="text" [(ngModel)]="description" name="description"
        maxlength="40">
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-sm-6">
          <label for="UnknownPartBuyoutModal_Select_PartBuyoutCoreOption">Core Option</label>
          <select id="UnknownPartBuyoutModal_Select_PartBuyoutCoreOption" name="UnknownPartBuyoutModal_Select_PartBuyoutCoreOption" class="form-control" [ngModel]="selectedPartBuyoutCoreOption"
            (ngModelChange)="onChangePartBuyout($event)" #partBuyoutCoreOption>
            <option id="UnknownPartBuyoutModal_Option_NoCore" selected value="NOCORE">No Core for Part</option>
            <option id="UnknownPartBuyoutModal_Option_NonInventoryCore" value="NICORE">Non-Inventory Core</option>
            <option id="UnknownPartBuyoutModal_Option_NonInventoryCoreWithReturn" value="NICORER">Non-Inventory Core w/ Return</option>
          </select>
        </div>
        <div class="col-sm-6">
          <label for="UnknownPartBuyoutModal_Text_PartsBuyoutQuantity">Quantity</label>
          <div class="input-group spinner">
            <part-quantity
              inputName="UnknownPartBuyoutModal_PartsBuyoutQuantity"
              inputPlaceholder="Quantity"
              inputAutoComplete="off"
              [value]="partsbuyoutQuantity"
              (onChangeQuantity)="changeQuantity($event)"
              idModifier="UnknownPartBuyoutModal"
            ></part-quantity>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="authorizationService.hasPermission(permission.ReadVendors) && !vendor">
      <div class="gx-2">
        <i id="UnknownPartBuyoutModal_I_VendorWarningIcon" class="fas fa-exclamation-triangle text-danger" aria-hidden="true"></i>
        <span id="UnknownPartBuyoutModal_Span_VendorWarning" class="strong">Warning: Vendor is required.</span>
      </div>
    </div>
    <div *ngIf="isPartNumberReturned">
      <div class="gx-2">
        <i id="UnknownPartBuyoutModal_I_ChangeMaterialWarningIcon" class="fas fa-exclamation-triangle text-danger" aria-hidden="true"></i>
        <span id="UnknownPartBuyoutModal_Span_ChangeMaterialWarning" class="strong">Warning: Change material PARTSBUYOUTTX with any one of the following:</span>
      </div>
      <ul class="list-unstyled">
        <li *ngFor="let part of knownParts; let i = index">
          <a id="UnknownPartBuyoutModal_A_PartSearch_{{i}}" [routerLink]="['/parts']" [queryParams]="{searchTerm:part.rushPartNumber,partId:part.partId, src:source}"
            (click)="closeModal()">{{part.number}}
          </a>
        </li>
      </ul>
    </div>
    <div *ngIf="isBuyoutGreaterthanSales">
      <div class="gx-2">
        <i id="UnknownPartBuyoutModal_I_BuyoutAmountHigherThanSalesPriceWarning" class="fas fa-exclamation-triangle text-danger" aria-hidden="true"></i>
        <span id="UnknownPartBuyoutModal_Span_BuyoutAmountHigherThanSalesPriceWarning" class="strong">Warning: The Buyout Amount is more than the Sales Price</span>
      </div>
      <div class="gx-2">
        <input id="UnknownPartBuyoutModal_Text_Warning" name="UnknownPartBuyoutModal_Text_Warning" #chkwarning value="option"
            type="checkbox" [(ngModel)]="chkwarningmodel" (change)="chkWarningclick($event)">
          <label for="UnknownPartBuyoutModal_Text_Warning">Yes, I realize that the Buyout Amount is more than Sales
          Price and I wish to continue with this Parts Buyout.</label>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" id="UnknownPartBuyoutModal_Button_SubmitPartBuyout"
    [disabled]="!authorizationService.hasPermission(permission.ReadVendors) || !authorizationService.hasPermission(permission.CreatePartBuyout) || !vendor || !isValid || !partNoOnly || !description || !price"
    (click)="onSubmit({partNo,partNoOnly,description,manufacturer,price,partsbuyoutQuantity,partBuyoutCoreOption: partBuyoutCoreOption.value})">Submit</button>
  <button type="button" class="btn btn-primary" id="UnknownPartBuyoutModal_Button_CancelPartBuyout"
    [disabled]="!partNoOnly && !description && !price" (click)="closeModal()">Cancel</button>
</div>
</ng-container>
