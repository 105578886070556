/* eslint-disable arrow-body-style */
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from "@angular/forms";
import { CheckoutValidationMessages } from "app/_components/checkout-validation/checkout-validation.messages";
import { CheckoutDetailsFormGroup, CheckoutFormGroup } from "app/_components/checkout/checkout.component";
import { OrderType } from "entities/order-type";
import { isNullOrEmpty } from "validators/null-or-empty.validator";

export const validateTransactionTypeRequired = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const checkoutDetailsFormGroup =
      control as FormGroup<CheckoutDetailsFormGroup>;
    const transactionTypeValue: OrderType = checkoutDetailsFormGroup.value.transactionType;
    if (transactionTypeValue !== OrderType.Quote && transactionTypeValue !== OrderType.Order) {
      return { "transactionType.required": CheckoutValidationMessages["transactionType.required"] };
    }
    return null;
  };
};

export const validateOrderChannelRequired = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const checkoutFormGroup: FormGroup<CheckoutFormGroup> = control.root as FormGroup;
    const isInternal = checkoutFormGroup?.value?.shippingAndDelivery?.cartData?.isUserInternal;
    const checkoutDetailsFormGroup =
      control as FormGroup<CheckoutDetailsFormGroup>;
    const transactionType = checkoutDetailsFormGroup.value.transactionType;
    const orderChannelValue: string | null | undefined = checkoutDetailsFormGroup.value.orderChannel;
    if ((orderChannelValue === null ||
         orderChannelValue === undefined) &&
         transactionType === OrderType.Order &&
         isInternal) {
      return { "orderChannel.required": CheckoutValidationMessages["orderChannel.required"] };
    }
    return null;
  };
};

export const isPONumberRequired = (
  transactionType: OrderType,
  isPONumberRequiredForCustomer: boolean
): boolean => {
  return isPONumberRequiredForCustomer && transactionType != OrderType.Quote;
}

export const validatePONumberRequired = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const checkoutFormGroup = control.root as FormGroup<CheckoutFormGroup>;
    const checkoutDetailsFormGroup = control as FormGroup<CheckoutDetailsFormGroup>;
    const poNumberValue = checkoutDetailsFormGroup.value.poNumber;
    const transactionType = checkoutDetailsFormGroup.value.transactionType;
    const isPONumberRequiredForCustomer = checkoutFormGroup.value?.customer?.isPONumberRequired;
    if (isPONumberRequired(transactionType, isPONumberRequiredForCustomer) && isNullOrEmpty(poNumberValue)) {
      return { "poNumber.required": CheckoutValidationMessages["poNumber.required"] };
    }
    return null;
  };
};

export const validateUnitNumberPattern = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const specialCharacterRegex = /[^A-Za-z0-9]/;
    const checkoutDetailsFormGroup =
      control as FormGroup<CheckoutDetailsFormGroup>;
    const unitNumber = checkoutDetailsFormGroup.value.unitNumber;
    if (!isNullOrEmpty(unitNumber) && specialCharacterRegex.test(unitNumber)) {
      return { "unitNumber.pattern": CheckoutValidationMessages["unitNumber.pattern"] };
    }
    return null;
  };
};
