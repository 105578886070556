import { Component, Inject, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NOTIFICATION_TIME_INTERVAL_IN_MIN, PACCAR_URL, RUSHCARE_URL, VIN_PARTS_SEARCH_URL } from 'app/app.constants';
import { OpenItemType, Permission } from 'entities/enums';
import { NotificationModel } from "entities/notification-model";
import { User } from 'entities/user';
import { NavistarPunchoutModalComponent } from 'modals/punchout-modals/navistar-punchout-modal.component';
import { EMPTY, Observable, Subject, Subscription, combineLatest, concat, interval, merge, of } from 'rxjs';
import { catchError, filter, map, mapTo, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthorizationService } from 'services/authorization.service';
import { ConfigurationService } from 'services/configuration.service';
import { LoggerService } from 'services/logger.service';
import { MyDashboardService } from 'services/my-dashboard.service';
import { ToastService } from 'services/toast.service';
import { UserNotificationService } from 'services/user-notification.service';
import { AppState } from 'store/app-state';
import * as BranchActions from "store/branch/branch.actions";
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import * as CustomerActions from 'store/customer/customer.actions';
import * as FeatureFlagSelectors from 'store/feature-flags/feature-flags.selectors';
import * as FeaturedPartsActions from 'store/featured-parts/featured-parts.actions';
import * as HomeActions from "store/home/home-page.actions";
import * as LoyaltyActions from 'store/loyalty/loyalty.actions';
import * as MyDasboardSelectors from 'store/my-dashboard/my-dashboard.selectors';
import { AuthenticationService } from '../../_modules/authentication/authentication.service';

@Component({
  selector: 'header-nav',
  templateUrl: './header-nav.component.html'
})
export class HeaderNavComponent implements OnDestroy {
  public userNotificationSubject: Subject<NotificationModel> = new Subject();
  public markAllNotificationsSubject: Subject<{}> = new Subject();

  public permission = Permission;
  public loadingOpenItemsCount: boolean = false;
  public openItemCount$: Observable<number>;
  public headerNavShowFavorites$: Observable<boolean>;
  public userProfile$: Observable<{ unique_name?: string }> = this.authenticationService.userProfile$;
  public user$: Observable<User> = this.store.select(ConfigurationSelectors.selectUserInfo);
  public navistarPunchoutEnabled$: Observable<boolean> = this.store.select(FeatureFlagSelectors.isFeatureActive('NavistarPunchout'));
  public backCounter$: Observable<boolean> = this.store.select(ConfigurationSelectors.hasPermission(Permission.Backcounter));
  public backCounterToolFeatureFlag$ = this.store.select(FeatureFlagSelectors.isFeatureActive('BackCounterTool.Dashboard'));
  private subscription: Subscription;
  public rccUrl = RUSHCARE_URL;
  public vinSearchUrl = VIN_PARTS_SEARCH_URL;

  openItemType = OpenItemType;
  paccarUrl = PACCAR_URL;

  constructor(
    private router: Router,
    private toastService: ToastService,
    private myDashboardService: MyDashboardService,
    private configurationService: ConfigurationService,
    public userNotificationService: UserNotificationService,
    private authenticationService: AuthenticationService,
    public authorizationService: AuthorizationService,
    private loggerService: LoggerService,
    private modalService: NgbModal,
    private store: Store<AppState>,
    @Inject(NOTIFICATION_TIME_INTERVAL_IN_MIN) public NOTIFICATION_TIME_INTERVAL_IN_MIN: number
  ) {
    const routeChangeObs = this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd)
      );

    const notificationInveralMs = NOTIFICATION_TIME_INTERVAL_IN_MIN * 60 * 1000;

    this.headerNavShowFavorites$ = routeChangeObs
      .pipe(
        shareReplay(1),
        map(() => {
          this.loggerService.verbose("url: ", this.router.url);
          if (this.router.url.startsWith("/parts?searchTerm") || this.router.url === "/cart") {
            return true;
          } else {
            return false;
          }
        })
      );

    this.openItemCount$ = combineLatest([
        this.store.select(MyDasboardSelectors.showQuotesForAllBranches),
        this.store.select(BranchSelectors.selectedBranch),
        this.store.select(MyDasboardSelectors.getQuotesForBranchCode)
    ]).pipe(
        filter(([_, branch]) => branch.code && branch.code !== ''),
        switchMap(([showQuotesForAllBranches, branch, myDasboardBranchCode]) => concat(of(null), interval(notificationInveralMs))
          .pipe(
            mapTo({showQuotesForAllBranches, branchCode: myDasboardBranchCode ? myDasboardBranchCode : branch.code})
          )
        ),
        tap(() => this.loadingOpenItemsCount = true),
        switchMap(({showQuotesForAllBranches, branchCode}) => this.myDashboardService.GetOpenItemsCount(showQuotesForAllBranches ? '' : branchCode)
          .pipe(
            map(({ openQuoteCount }) => openQuoteCount),
            catchError((error) => {
              this.toastService.errorMessage('HeaderNavComponent', 'getOpenItemsCount', 'getOpenItemsCount', null);
              return of(0);
            })
          )
        ),
        tap(() => this.loadingOpenItemsCount = false)
      );

    const userNotificationObs = this.userNotificationSubject
      .pipe(
        withLatestFrom(this.configurationService.user$),
        filter(([, user]) => user.isInternal && this.authorizationService.hasPermission(Permission.ReadNotifications)),
        map(([userNotification, ]) => userNotification),
        tap((userNotification: NotificationModel) => {
          if (userNotification.notification.notificationTypeId == 1) {
            this.userNotificationService.showSalesMetrics(userNotification.notificationId);
          }
        }),
        filter((userNotification) => userNotification.isRead == false && this.authorizationService.hasPermission(Permission.UpdateNotifications)),
        switchMap((userNotification: NotificationModel) => {
          return this.userNotificationService.markNotification(userNotification.notificationId)
            .pipe(
              catchError((error) => {
                this.toastService.errorMessage('HeaderNavComponent', 'userNotificationObs', 'markNotification', error);
                return EMPTY;
              })
            )
        }),
        tap(() => {
          this.userNotificationService.updateNotifications();
        })
      );

    const markAllNotificationsObs = this.markAllNotificationsSubject
      .pipe(
        switchMap((values) => {
          return this.userNotificationService.markAllNotifications()
            .pipe(
              catchError((error) => {
                this.toastService.errorMessage('HeaderNavComponent', 'markAllNotificationsObs', 'markAllNotifications', error);
                return EMPTY;
              })
            );
        }),
        tap(() => {
          this.userNotificationService.updateNotifications();
        })
      );

    this.subscription = merge(userNotificationObs, markAllNotificationsObs).subscribe();
  }

  openNavistarPunchoutModal() {
    this.modalService.open(NavistarPunchoutModalComponent)
      .result
      .catch(() => { });
  }

  editProfile() {
    this.authenticationService.editProfile();
  }

  goBackToHomePage(isInternal: boolean) {
    if(isInternal){
      this.store.dispatch(LoyaltyActions.clearLoyaltyAccount());
      this.store.dispatch(CustomerActions.clearCustomer());
      this.store.dispatch(HomeActions.loaded());
    }
    this.store.dispatch(BranchActions.setDefaultBranch());
    this.store.dispatch(FeaturedPartsActions.clearFeaturedParts());
    this.router.navigate(['/']);
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.authenticationService.logout();
  }
  ngOnDestroy() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
