import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Branch } from "entities/branch";
import { blankId } from "helpers/blank-id";
import { combineLatest, of } from "rxjs";
import { catchError, filter, map, switchMap, withLatestFrom } from "rxjs/operators";
import { BranchService } from "services/branch.service";
import { AppState } from "store/app-state";
import * as BranchActions from 'store/branch/branch.actions';
import * as BranchSelectors from "store/branch/branch.selectors";
import * as CartActions from "store/cart/cart.actions";
import * as CartSelectors from "store/cart/cart.selectors";
import * as ConfigurationActions from 'store/configuration/configuration.actions';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import * as ProvincesActions from "store/province/province.actions";

@Injectable()
export class BranchEffects {

  getBranches$ = createEffect(() => this.action$.pipe(
    ofType(BranchActions.getBranches),
    switchMap(
      () => this.branchService.GetUserBranchesForOrdering().pipe(
        map(
          branches => BranchActions.getBranchesSuccess({ branches }),
          catchError(error => of(BranchActions.getBranchesFailed({ error })))
        )
      )
    )

  ));

  setDefaultBranch$ = createEffect(() => combineLatest([
    this.action$.pipe(ofType(ConfigurationActions.getConfigurationSuccess)),
    this.store.select(BranchSelectors.selectBranchesForOrdering)]).pipe(
    filter(() => this.router.url === '/'),
    withLatestFrom(
      this.store.select(BranchSelectors.selectedBranch),
      this.store.select(CartSelectors.selectCartId)
    ),
    filter(([_, __, cartId]) => cartId === blankId || cartId === null || cartId === undefined),
    map(([[{configuration}, branches], selectedBranch]) => {
      let startingBranch: Branch = null;
      if (configuration.branch?.code !== null && ( selectedBranch?.code === null || selectedBranch?.code === undefined ) ) {
        startingBranch = branches.find(x => x.code === configuration.branch?.code);
      } else {
        startingBranch = branches.find(x => x.code === selectedBranch?.code);
      }
      return BranchActions.selectBranch({ branch: startingBranch });
    })
  ));

  resetDefaultBranch$ = createEffect(() => this.action$.pipe(
    ofType(BranchActions.setDefaultBranch),
    withLatestFrom(this.store.select(ConfigurationSelectors.selectBranch)),
    filter(([_, branch]) => !!branch),
    map(([_, branch]) => {
      return BranchActions.selectBranch({ branch: branch });
    })
  ));

  getProvincesList$ = createEffect(() => this.action$.pipe(
    ofType(
      BranchActions.selectBranch,
      CartActions.selectCartBranchSuccess,
      BranchActions.loadCachedStateSuccess,
      ConfigurationActions.getConfigurationSuccess
    ),
    withLatestFrom(this.store.select(BranchSelectors.selectedBranch)),
    filter(([_, branch]) => branch?.code != null ),
    map(([_, branch]) => ProvincesActions.provincesLoad({ countryCode: branch.country }))
  ));

  constructor(
    private router: Router,
    private action$: Actions,
    private branchService: BranchService,
    private store: Store<AppState>,
  ) {}
}
