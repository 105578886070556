import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppInsightSto } from 'entities/app-insight-sto';
import { Cart } from 'entities/cart-search';
import { AddStoCartItemRequest } from 'entities/carts/add-sto-cart-item-request';
import { Permission } from 'entities/enums';
import { InventoryInfo } from 'entities/national-inventory/inventory-info';
import { Part } from 'entities/part';
import { PartSearch } from 'entities/part-search';
import { ToastType } from 'entities/toast-type';
import { sortData } from 'helpers/sortData';
import { Observable } from 'rxjs';
import { AuthorizationService } from 'services/authorization.service';
import { CommonDataService } from 'services/common-data.service';
import { FeatureFlagService } from 'services/feature-flag.service';
import { LoggerService, SourceLocationType } from 'services/logger.service';
import { NationalInventoryService } from 'services/national-inventory.service';
import { PartService } from 'services/part.service';
import { ToastService } from 'services/toast.service';
import { AppState } from 'store/app-state';
import * as CartActions from 'store/cart/cart.actions';
import * as CartSelectors from 'store/cart/cart.selectors';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';

@Component({
  selector: 'national-inventory-modal',
  templateUrl: './national-inventory-modal.component.html',
  styleUrls: ['./national-inventory-modal.component.scss']
})
export class NationalInventoryModalComponent implements OnInit, OnDestroy {
  inventories: any;
  filteredInventories: any;
  filterKey: string = '';
  isPartMissingFromSolr = false;
  partsData: Part = null;
  tempPartsData: any = null;
  partId: string;
  partNumber: string;
  subscription: any;
  sortBy: string = '';
  sortAsc: boolean = false;
  partType: string = '';
  partWithSource: any = null;
  removeLineItem = false;
  respart: any;
  permission: any = Permission;
  @Output() addedToCart: EventEmitter<Part> = new EventEmitter();

  cartId: string;
  userId: string;
  customerName: string;
  customerNumber: string;
  branchCode: string;

  cartIsLocked$: Observable<boolean> = this.store.select(CartSelectors.selectIsCartLocked);
  isInternal$: Observable<boolean> = this.store.select(ConfigurationSelectors.isInternal);

  constructor(
    private store: Store<AppState>,
    private toastService: ToastService,
    private commonDataService: CommonDataService,
    public authorizationService: AuthorizationService,
    private nationalInventoryService: NationalInventoryService,
    private partService: PartService,
    private loggerService: LoggerService,
    private activeModal: NgbActiveModal,
    private featureFlagService: FeatureFlagService,
    ) {
  }

  ngOnInit() {

    this.filterKey = '';
    this.partWithSource = null;
    this.inventories = null;

    if (
      this.respart.hasOwnProperty('partId') && this.respart.partId != null &&
      ((this.respart.hasOwnProperty('rushPartNumber') && this.respart.rushPartNumber != null) || (this.respart.hasOwnProperty('partNumber') && this.respart.partNumber != null) || (this.respart.hasOwnProperty('partNo') && this.respart.partNo != null))
    ) {
      this.partsData = this.respart;
      this.tempPartsData = this.respart;
      if (this.respart.source != undefined && this.respart.source != null && this.respart.source == SourceLocationType[SourceLocationType.Cart]) {
        this.partWithSource = this.respart;
      }
      this.partId = this.respart.partId;
      this.partNumber = this.respart.rushPartNumber != undefined && this.respart.rushPartNumber != null ? this.respart.rushPartNumber : (this.respart.partNumber != undefined && this.respart.partNumber != null ? this.respart.partNumber : this.respart.partNo);
      this.getParts();
      this.partType = '';
      if (this.respart.type) {
        this.partType = this.respart.type;
      }
    } else {
      this.isPartMissingFromSolr = true;
      this.inventories = [];
    }
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  getInventory(): void {
    this.filterKey = '';
    this.nationalInventoryService.getInventory(this.partId, this.partNumber, this.branchCode, this.customerNumber)
      .then((getInventoryResult) => {
        if (getInventoryResult.ErrorType && getInventoryResult.ErrorType != 200) {
          this.closeModal();
          this.toastService.errorMessage('NationalInventoryModalComponent', 'getInventory', 'getInventory', getInventoryResult);
        } else if (getInventoryResult && getInventoryResult.length) {
          var inventoryArray: InventoryInfo[] = getInventoryResult[0].inventory;
          this.inventories = inventoryArray.map(item => {
            item.partsQuantity = 1;
            return item;
          });
            if (this.inventories) {
              this.filteredInventories = this.inventories;
            } else {
              this.filteredInventories = [];
            }
        } else {
          this.inventories = [];
        }

        this.loggerService.verbose('National Inventory data : ', getInventoryResult);
      },
        () => { this.closeModal(); });
  }

  filter = function () {
    if (this.filterKey != '') {
      this.filteredInventories = this.inventories.filter(function (e) {
        return ((e.branchCode && ('' + e.branchCode).indexOf(this.filterKey.toLowerCase()) > -1) ||
          (e.branchName && e.branchName.toLowerCase().indexOf(this.filterKey.toLowerCase()) > -1) ||
          (e.milesFromSource && ('' + e.milesFromSource).indexOf(this.filterKey.toLowerCase()) > -1) ||
          (e.quantity && ('' + e.quantity).indexOf(this.filterKey.toLowerCase()) > -1)) == true;

      }.bind(this));
    } else {
      this.filteredInventories = this.inventories;
    }
  };

  sortDataBy(sortBy: string) {
    if (this.sortBy == sortBy) {
      this.sortAsc = !this.sortAsc;
    } else {
      this.sortAsc = true;
    }

    this.sortBy = sortBy;

    this.filteredInventories = sortData(this.filteredInventories, this.sortBy, this.sortAsc);
  }

  addToCart(addToCartItem: InventoryInfo) {
    let trackingParameters: string = '';
    if (this.partType == 'related') {
      trackingParameters = JSON.stringify({
        AddedFrom: 'RelatedPart',
        PartId: this.partsData.partId,
        GroupRelatedPartId: this.partsData.groupRelatedPartId,
        BranchCode: this.branchCode,
        MaterialId: this.partsData.rushPartNumber
      });
    } else if (this.partType == 'alternate') {
      trackingParameters = JSON.stringify({
        AddedFrom: 'AlternatePart',
        PartId: this.partsData.partId,
        GroupPartId: this.partsData.groupId,
        BranchCode: this.branchCode,
        MaterialId: this.partsData.rushPartNumber
      });
    }

    this.logAddItemToCartToAppInsights(addToCartItem, trackingParameters);

    const part: Part = {
      partId: this.partsData.partId,
      number: "",
      partNumberStrpd: "",
      rushPartNumber: this.partsData.rushPartNumber,
      name: "",
      vmrsDescription: this.partsData.vmrsDescription,
      partNumberBase: "",
      vmrsCode: this.partsData.vmrsCode,
      description: "",
      image: "",
      manufacturer: this.partsData.manufacturer,
      cateogory: this.partsData.cateogory,
      isReman: false,
      specification: [],
      listPrice: this.partsData.listPrice,
      coreCostPrice: 0,
      yourPrice: this.partsData.yourPrice,
      cost: this.partsData.cost,
      quantityAvailable: addToCartItem.quantity,
      isObsolete: false,
      supersededPartNumber: "",
      core: false,
      corePart: "",
      corePrice: this.partsData.corePrice,
      unitOfMeasure: "",
      groupId: 0,
      groupRelatedPartId: 0,
      branchName: this.commonDataService.Branch.name,
      binLocation: this.partsData.binLocation,
      quantity: addToCartItem.partsQuantity,
      confidence: 0,
      isSAPPart: false,
      comments: "",
      isSpecialPricing: false,
      coupons: [],
      walkIn: 0,
      overrideTarget: 0,
      overrideFloor: 0,
      overrideFloorActive: 0,
      isFavoritePart: false,
      couponTotal: 0,
      priceWithCouponApplied: 0,
      adjustedPrice: this.tempPartsData.adjustedPrice,
      priceOverrideReasonId: 0,
      isExtendedToBranch: false,
      isRushPart: true
    } as Part;

    if (this.partWithSource != undefined && this.partWithSource != null && this.partWithSource.source == SourceLocationType[SourceLocationType.Cart]) {
      this.removeLineItem = this.partWithSource.isSplit ? this.partWithSource.isSplit : this.removeLineItem;
    }

    const stoCartItemRequest: AddStoCartItemRequest = {
      addCartItemRequest: {
        binLocation: '',
        partId: this.partsData.partId,
        coreOption: 'NOCORER',
        partNumber: this.partsData.rushPartNumber,
        quantity: addToCartItem.partsQuantity,
        trackingParameters: trackingParameters,
        adjustedPrice: 0,
        sapCoupons: this.partsData.sapCoupons
      },
      originalPartCartItemId: null,
      removeItemsFromCartRequest: null,
      vendorCode: addToCartItem.branchCode,
      vendorBranchCode: addToCartItem.branchCode,
      vendorBranchName: addToCartItem.branchName
    };

    if (this.removeLineItem) {
      stoCartItemRequest.removeItemsFromCartRequest = {
        cartId: this.cartId,
        isPickupDeliveryType: false,
        itemsToRemoveFromCart: [{
          cartItemId: this.partWithSource?.cartItemId
        }]
      };
    }

    this.store.dispatch(CartActions.addStoItemFromNationalInventory({ addCartItemRequest: stoCartItemRequest, partData: this.partsData }))
  }

  logAddItemToCartToAppInsights(addToCartItem: InventoryInfo, trackingParameters: string) {
    const addToCartParametersForTracking = Object.assign(new Cart(), {
      userId: this.userId,
      branchCode: this.branchCode,
      customerNumber: this.customerNumber,
      partNumber: this.partsData.rushPartNumber,
      description: this.partsData.description,
      yourPrice: this.partsData.yourPrice,
      quantity: addToCartItem.partsQuantity,
      coreOption: 'NOCORER',
      corePrice: this.partsData.corePrice,
      corePartNumber: this.partsData.corePartNumber,
      partId: this.partsData.partId,
      cost: this.partsData.cost,
      listPrice: this.partsData.listPrice,
      adjustedPrice: this.tempPartsData.adjustedPrice, // this.partsData.adjustedPrice,
      rebateField: this.tempPartsData.rebateField,
      isPriceVerified: this.tempPartsData.isPriceVerified,
      verifiedPrice: this.tempPartsData.verifiedPrice,
      priceOverrideReasonId: this.partsData.priceOverrideReasonId,
      PartNumOnly: this.partsData.partNumberOnly,
      vmrsCode: this.partsData.vmrsCode,
      vmrsDescription: this.partsData.vmrsDescription,
      manufacturer: this.partsData.manufacturer,
      cateogory: this.partsData.cateogory,
      binLocation: this.partsData.binLocation,
      deliveryType: '',
      vendorCode: addToCartItem.branchCode,
      VendorBranchCode: addToCartItem.branchCode,
      VendorName: addToCartItem.branchName,
      TrackingParameters: trackingParameters,
      IsSTO: true,
      QuantityAvailable: addToCartItem.quantity,
      cartId: this.cartId
    });

    let sourceName = 'NationalInventoryModalComponent_addToCart__STO';
    let metricName = this.loggerService.getMetricValue(sourceName);
    let appInsightSTO = Object.assign(new AppInsightSto(), {
      userId: this.userId,
      customerNumber: this.customerNumber,
      customerName: this.customerName,
      branchNumber: this.commonDataService.Branch.code,
      cartDetails: JSON.stringify(this.commonDataService.CartDetails),
      vendorNumber: addToCartItem.branchCode,
      vendorName: addToCartItem.branchName,
      source: this.tempPartsData.source,
      plMetricName: sourceName
    });
    appInsightSTO.product = this.loggerService.getAppInsightParts(addToCartParametersForTracking, JSON.stringify(appInsightSTO).length);
    this.loggerService.trackMetric(metricName, appInsightSTO);
  }

  changeQuantity(qty: number, inventory: any) {
    inventory.partsQuantity = qty;
  }

  getParts(): void {
    this.partsData = null;

    let searchData: PartSearch = {
      ...new PartSearch(),
      partId: this.partId,
      partSearchTerm: this.partNumber,
      branchCode: this.branchCode,
      customerNumber: this.customerNumber,
      isCountCheck: false,
      pageNumber: 1,
      pageSize: 100
    };
    this.partService.getParts(searchData)
      .then((parts) => {
        if (parts.ErrorType != undefined && parts.ErrorType != null && parts.ErrorType != 200) {
          this.toastService.errorMessage('NationalInventoryModalComponent', 'getParts', 'getParts', parts);
        } else {
          if (parts == undefined || parts == null || parts.parts.length <= 0) {
            this.toastService.showToast('Part not found.', ToastType.Error);
          } else {
            this.partsData = parts.parts[0];
            this.removeLineItem = (this.partsData.quantityAvailable <= 0);
            this.getInventory();
          }
        }
        this.loggerService.verbose('National Inventory component parts data : ', parts);
      },
        () => { });
  }

  ngOnDestroy() {
    if (this.subscription != null && this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }
}
